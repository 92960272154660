<template>
    <div>
        <v-card outlined>
            <v-card-title class="title">
                Absent List
                <v-spacer></v-spacer>
                <!--<print-buttons  :download-url="downloadUrl"></print-buttons>-->
            </v-card-title>

            <v-card-title class="title pb-0">
                <v-flex>
                    <v-select :loading="loadingGrade" :disabled="grades.length<1"
                              @change="getSections()"
                              :items="grades"
                              class="pa-0" label="Select grade"
                              outlined dense
                              v-model="grade"/>
                </v-flex>
                <v-flex>
                    <v-select :loading="loadingSection" :disabled="sections.length<1"
                              :items="sections"
                              class="pa-0"
                              label="Select section"
                              outlined dense
                              v-model="section"/>
                </v-flex>
                <v-flex>
                    <v-calendar-field
                            id-val="attendance_date"
                            v-model="attendance_date"
                            label="Attendance Date">

                    </v-calendar-field>
                </v-flex>
                <v-flex>
                    <v-btn color="primary" class="mt-n8" outlined @click="get()">Search</v-btn>
                </v-flex>

            </v-card-title>
        </v-card>
        <v-container>
            <template>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-data-table
                                :headers="headers"
                                :items="form.items.data"
                                footer-props.items-per-page-options="rowsPerPageItems"
                                :options.sync="pagination"
                                :server-items-length="form.items.meta.total"
                                :loading="form.loading">
                            <template v-slot:item="{index, item}">
                                <tr>
                                    <td>{{ index + form.items.meta.from }}</td>
                                    <td class="text-xs-left">{{ item.roll }}</td>
                                    <td class="text-xs-left">{{ item.name }}</td>
                                    <td class="text-xs-left">{{ item.grade }}<sup>"{{item.section}}"</sup>
                                    </td>
                                    <td class="text-xs-left">{{ item.guardian_name }}
                                        <p>({{item.relation}})</p>
                                    </td>
                                    <td class="text-xs-left">{{item.mobile || '-'}}</td>
                                    <td class="text-xs-left">{{item.remarks}}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>
            </template>
        </v-container>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {getPropertyFromArrayObject, nepNumberToEng, engNumberToNep} from '@/library/helpers'
    import {validateDate} from "../../../../../library/helpers";

    const NepaliDate = require('nepali-date');


    export default {
        data: () => ({
            form: new Form({}, '/api/report/academic/attendance/absent-list'),
            grades: [],
            downloadUrl: '',
            grade: '',
            section: '',
            sections: [],
            loadingGrade: false,
            loadingSection: false,
            attendance_date: new NepaliDate(new Date()).format('YYYY-MM-DD'),
            // attendance_date: '',

            data: [],
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 10,
            },
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Roll', align: 'left', value: 'roll'},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Grade', align: 'left', value: 'grade'},
                {text: 'Guardian Name', align: 'left', value: 'guardian name'},
                {text: 'Mobile', align: 'left', value: 'mobile', sortable: false, width: 200},
                {text: 'Remarks', align: 'left', value: 'remarks', sortable: false, width: 200}
            ],
        }),

        computed: {
            ...mapState(['batch']),
        },
        mounted() {
            this.getGrades();
        },
        dateValidation: [
            (v) => validateDate(v) || 'Invalid Date Format',
        ],
        watch: {

            'section': function () {
                this.attendance_date = new NepaliDate(new Date()).format('YYYY-MM-DD');
            },
            'grade': function (v) {
                this.getSections();
                this.attendance_date = new NepaliDate(new Date()).format('YYYY-MM-DD');
            },
            'attendance_date': function (v) {
                this.form.items.data = [];
                // if (v && v.length === 10 && validateDate(v) ) this.get();
            }

        },
        methods: {
            nepNumberToEng,
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&gradeId=' + this.grade + '&sectionId=' + this.section + '&attendanceDate=' + this.attendance_date.dateForm();
            },


            get(params) {
                if ((this.section && this.attendance_date) || this.grade === 'all') {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                        this.data = data.students;
                        this.downloadUrl = data.download_url;
                    });
                }
            },


            getGrades() {
                this.sections = [];
                this.section = '';
                this.loadingGrade = true;
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true').then(({data}) => {
                    this.grades = [{value: 'all', text: 'All'}];
                    data.data.map(item => {
                        this.grades.push({value: item.id, text: item.name});
                    });
                }).finally(err => {
                    this.loadingGrade = false;
                })
            },
            getSections() {
                this.section = '';
                this.sections = [];
                if (this.grade !== 'all') {
                    this.loadingSection = true;
                    this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
                        this.sections = data.data.map(item => {
                            return {value: item.id, text: item.name}
                        });
                    }).finally(() => {
                        this.loadingSection = false;
                    })
                } else {
                    this.section = '0';
                }

            },
        }
    }
</script>